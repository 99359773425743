.scroll-top-icon {
  position: fixed;
  bottom: 50%;
  right: 2px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
  z-index: 9999;
  color: rgba(255, 255, 255, 0.795);
}

.scroll-top-icon:hover {
  color: #61DBFB;
  cursor: pointer;
}


.scroll-top-icon.show {
  opacity: 1;
  visibility: visible;
}