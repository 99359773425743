.projects-container {
  padding: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 150px;
  opacity: 0; 
  animation: fadeIn 1s ease-in forwards; 
  animation-delay: 6.5s; 
}

#webDesignSection, #gamesSection, #photoSection {
  margin-top: 50px;
}

#photoSection {
  margin-bottom: 50px;
}

@keyframes fadeIn {
  from {
    opacity: 0; 
  }
  to {
    opacity: 1; 
  }
}

.projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  grid-template-rows: repeat(2, auto);
  gap: 5px;
}

.photo-projects-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr); 
  grid-template-rows: repeat(3, auto);
  gap: 5px;
}

.projects-header {
  color: rgb(186, 199, 223);
  font-family: "Roboto", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-size: 62px;
  align-self: flex-start;
  position: relative;
  margin-left: 20px;
}

.projects-section-title {
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
  color:  #61dcfbb7;
  align-self: flex-start;
  position: relative;
  margin-left: 25px;
  margin-bottom: 50px;
}

.projects-section-title::after {
    content: ''; 
    position: absolute; 
    bottom: 40%; 
    left: (100% + 10px) ;
    width: 35vw; 
    height: 0.5px; 
    background-color: #61dcfb87;
    transform: translateY(50%);
}

.project-card, .photo-project-card {
  position: relative;
  background-color: rgba(18, 21, 45, 0.882);
  border: solid rgba(56, 63, 110, 0.307) 1px;
  border-radius: 8px;
  overflow: hidden;
  /* height: 100%; */
  height: 50vh;
  box-sizing: border-box;
  margin-bottom: 5px;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo-project-card img {
  width: 65vw;
}

.project-card img {
  max-width: calc(100% - 90px);  
  height: 100%; 
  object-fit: contain;
  margin-left: 55px;
  cursor: pointer;
}

.project-card:hover img {
  position: relative;
  cursor: pointer;
  z-index: 1;
  filter: blur(10px) brightness(50%);
}

.project-title {
  position: absolute;
  left: 50%;
  width: 100%;
  transform: translate(-50%, 25%);
  color: rgb(186, 199, 223);
  font-size: 46px;
  text-align: center;
  z-index: 1;
  transition: opacity 0.3s ease;
  font-weight: 500;
  cursor: default;
  opacity: 0;
}

.project-description {
  color: rgba(186, 199, 223, 0.857);
  border: solid rgba(56, 63, 110, 0.545) 1.5px;
  padding: 10px;
  border-radius: 12px;
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 35vw;
  transform: translate(-50%, -50%);
  font-size: 12.5px;
  font-weight: 600;
  cursor: pointer;
  backdrop-filter: blur(50px);
  opacity: 0;
}

.project-links {
  position: absolute;
  top: 10px;
  right: 12px;
  display: flex;
  z-index: 2;
  font-size: 14px;
}

.project-links a {
  margin-left: 3px;
  color: rgba(255, 255, 255, 0.699);
  text-decoration: none;
}

.project-links a:hover {
  color: #61DBFB;
  transition: 0.3s ease-in;
  transform: translateY(-3px);
}

.project-card:hover .project-title, .project-card:hover .project-description {
  opacity: 1;
  transition: 0.3s ease-in;
}

.project-card .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  background-color: rgba(0, 0, 0, 0.555);
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
  backdrop-filter: blur(5px);
  visibility: hidden;
}

.project-card:hover .overlay {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 2000px) {
  .project-card {
    gap: 20px;
    width: 100%; 
  }

  .project-title {
    font-size: 40px;
    top: -3%;
  }
}

@media (max-width: 1500px) {
  .project-title {
    font-size: 36px;
    top: -3.7%;
  }
}

@media (max-width: 1100px) {
  .projects-grid {
    grid-template-columns: 1fr; 
    grid-template-rows: auto; 
  }

  .project-card {
    height: 50vh;
     width: calc(100vw - 55px);
  }

  .project-card img {
    width: 100vw;
  }

  .project-title {
    top: 35%;
    font-size: 44px;
  }

  .project-description {
    top: 75%;
    width: 65vw;
    font-size: 13.5px;
  }
}

@media (max-width: 850px) {
  .project-description {
    font-size: 12px;
    width: 80vw;
  }
}

@media (max-width: 775px) {
  .project-title {
    top: 3.5%;
    font-size: 40px;
  }

  .project-description {
    font-size: 12px;
    width: 70vw;
    top: 50%;
  }
}

@media (max-width: 650px) {
  .projects-header {
    font-size: 46px;
  }

  .project-title {
    top: 6%;
    font-size: 38px;
  }

  .project-description {
    font-size: 11.5px;
    width: 65vw;
  }
}

@media (max-width: 550px) {
  .project-title {
    top: 4%;
    font-size: 36px;
    width: 100%;
  }

  .project-description {
    top: 87%;
    font-size: 10px;
    width: 88vw;
  }
}

@media (max-width: 500px) {
  .project-title {
    font-size: 30px;
    top: 8%;
  }
  .project-description {
    width: 82vw;
    top: 85%;
  }
}


@media (max-width: 430px) {
  .projects-header {
    top: 10px;
    font-size: 28px;
  }

  .project-title {
    top: 9.5%;
  }
}

@media (max-width: 429px) {
  .project-title {
    font-size: 28px;
    top: 10%;
  }

  .project-description {
    font-size: 9px;
  }
}

.tools {
  color: #61dcfba0;
  position: relative;
}

@media (max-width: 650px) {
  .tools {
  }

@media (max-width: 430px) {
  .tools {
    top: 5px;
  }
}
}



