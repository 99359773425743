.sidebar {
  position: absolute;
  top: 25px;
  left: 0;
  width: 250px;
  height: 62vh;
  background-color: rgba(18, 21, 45, 0.882);
  color: rgba(186, 199, 223, 0.795);
  padding: 20px;
  overflow-y: none;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  border-radius: 20px;
  backdrop-filter: blur(50px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.15);
  border: solid rgba(56, 63, 110, 0.307) 1px;
}

.sidebar.show {
  transform: translateX(0);
}

.sidebar-content {
  padding-right: 10px;
}

.sidebar h2 {
  margin-bottom: 20px;
  color: rgba(186, 199, 223, 0.908);
  position: relative;
  font-size: 2.0em;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  padding: 10px 0;
  border-bottom: 1px solid #555555aa;
  font-size: 14.5px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.sidebar li:hover {
  color: #61DBFB;
  font-weight: 800;
  cursor: pointer;
  transition: 0.3s ease;
}

.sidebar li:focus, .sidebar-li:active {
  color: #61DBFB;
  font-weight: 800;
}

h5 {
  font-size: 0.6em;
  font-weight: 900;
}

.sidebar li:last-child {
  border-bottom: none;
} 

.contact-icons {
    position: relative;
    flex-direction: column;
}
  
.contact-icons .icon {
    color: rgba(255, 255, 255, 0.817);
    font-size: 18px;
    margin-right: 4px;
}

.contact-icons .icon:hover {
  color: #61DBFB;
  transition: 0.3s ease-in;
  transform: translateY(-3px);
  cursor: pointer;
}

@media (min-width: 1460px) and (max-width: 1580px) {
  .sidebar {
    width: 200px;
  }
}

@media (max-width: 1460px) {
  .sidebar {
    width: 180px;
  }

  .sidebar h2 {
    font-size: 1.6em;
  }
}

@media (max-width: 900px) {
  .sidebar {
    width: 136px;
  }
}

@media (max-width: 785px) {
  .sidebar {
    width: 130px;
  }
}

@media (max-width: 650px) {
  .sidebar h2 {
    font-size: 22px;
  }

  .sidebar li {
    font-size: 12px;
  }
}

@media (max-width: 585px) {
  .sidebar {
    width: 118px;
    font-size: 12.5px;
  }

  .sidebar h2 {
    font-size: 20px;
  }
}

@media (min-width: 410px) and (max-width: 480px) {
  .sidebar {
    width: 105px;
  }

  .sidebar h2 {
    font-size: 17px;
  }

  .sidebar li {
    font-size: 11px;
  }

  h5 {
    font-size: 7px;
  }

  .contact-icons .icon {
    font-size: 12px;
    margin-right: 1px;
  }
}

@media (max-width: 430px) {
  .sidebar {
    width: 100px;
  }
}

@media (max-width: 409px) {
  .sidebar {
    width: 90px;
  }

  .sidebar h2 {
    font-size: 16px;
    right: 5px;
  }

  .sidebar li {
    font-size: 10.5px;
  }

  .contact-icons .icon {
    font-size: 10px;
    margin-right: 1px;
  }
}

@media (max-width: 390px) {
  .sidebar {
    width: 85px;
  }

  .sidebar h2 {
    right: 7px;
  }
}

@media (max-width: 364px) {
  .sidebar {
    opacity: 0;
    transition: 0.3s ease-out;
  }
}

#wip {
  color: rgba(186, 199, 223, 0.35);
  cursor:not-allowed;
}