.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-icon {
  width: 40px;
  height: 40px; 
  animation: spin 1.5s linear infinite; 
  color: #61DBFB;
  margin-left: 25px;
}

.loading-text {
  font-size: 18px; 
  color: rgba(186, 199, 223, 0.795);
  margin-left: 15px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
