.navbar {
  background-color: rgba(18, 21, 45, 0.384);
  border-bottom: solid rgba(56, 63, 110, 0.688) 1px;
  backdrop-filter: blur(60px);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.logo {
  font-weight: 800;
  margin-left: 5px;
  color: #61dcfb;
  border: solid rgba(56, 63, 110, 0.688) 1px;
  padding-top: 0;
  padding-left: 12px;
  padding-right: 12px;
  position: relative;
  letter-spacing: -1.2px;
}

.logo .asterisk {
  font-size: 12px;
  position: relative;
  top: 0px;
}

.logo:hover {
  color: #61DBFB;
  border: solid rgb(56, 63, 110) 1px;
}

.navbar-toggler {
  margin-left: auto; 
  outline: none;
  border: none;
  box-shadow: none;   
  background-color: none;
  color: inherit;
  --bs-navbar-toggler-icon-bg: none !important; 

}

button.navbar-toggler,
span.navbar-toggler-icon {
  box-shadow: none !important; 
}


.navbar-toggler-icon {
  border: none;
  outline: none !important;
  background-color: none;
  box-shadow: none;
  color: none;
}

#basic-navbar-nav.navbar-collapse {
  margin-left: 12px;
  transition: 0s ease;
  font-size: 14px;
}

#basic-navbar-nav.navbar-collapse.show > .navbar-nav {
  float: right;
  font-size: 12px;
  position: relative;
  left: 6px;
  transition: 0.5s ease-in-out;
}

.navbar-nav .nav-link {
  color: rgb(186, 199, 223);
  margin-right: 20px;
}

.navbar-nav .nav-link:hover {
  color: #61DBFB;
  font-weight: 600;
}

.active {
  color: #61DBFB !important;
  font-weight: 800;
}


.navbar-toggler-icon {
  position: relative;
  display: inline-block;
  width: 35px; 
  height: 10px; 
  box-shadow: none;
  outline: none;
  border: none;
  color: none;
  transition: width 0.5s, height 0.5s;
}

.navbar-toggler-icon::before {
  content: '';
  position: absolute;
  background-color: rgb(186, 199, 223);
  top: 0;
  left: 0;
  width: 40%; 
  height: 1px; 
  transition: width 0.8s, height 0.8s; 
}

.navbar-toggler-icon::after {
  content: '';
  position: absolute;
  background-color: rgb(186, 199, 223);

  top: 50%; 
  left: 0;
  width: 80%; 
  height: 1px; 
  transform: translateY(-50%); 
  transition: width 0.8s, height 0.8s; 
}

.navbar-toggler-icon:hover::before {
  width: 65%;
  height: 1.5px;
  background-color: #61DBFB;
}

.navbar-toggler-icon:hover::after {
  width: 40%;
  height: 1.5px;
  background-color: #61DBFB;
}
