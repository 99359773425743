.about-container {
  margin: 40px auto; 
  max-width: 100%;
  width: 45%; 
  padding: 20px; 
  display: grid;
  place-items: center;
  opacity: 0; 
  animation: fadeIn 0.3s ease-in forwards; 
  animation-delay: 0.5; 
}

@keyframes fadeIn {
  from {
    opacity: 0; 
  }
  to {
    opacity: 1; 
  }
}

.about-container h1 {
  color: rgba(234, 234, 234, 0.795);
  text-align: center;
}

.about-container p, .about-container .archive {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: rgba(18, 21, 45, 0.882);
  padding: 20px;
  border: solid rgba(56, 63, 110, 0.307) 1.5px;
  border-radius: 10px;
  font-size: 21.5px;
  font-weight: 500;
  color: rgba(234, 234, 234, 0.795);
  backdrop-filter: blur(50px);
}

.features {
  font-size: 20px;
  font-weight: 400;
}

.about-container .archive {
  font-family: inherit;
  width: 100%;
  font-size: 14px;
}

.archive strong {
  font-weight: 600;
}

.archive a {
  text-decoration: none;
  font-style: italic;
  color: #61dcfbe6;
}

.archive a:hover {
  text-decoration: none;
  font-style: italic;
  font-weight: 500;
  color: #61dcfb;
}

.about-container ul {
  list-style: none;
}

@media (max-width: 1500px) {
  .about-container {
    width: 80%;
  }

  .about-container p {
    font-size: 18px;
  }
}

@media (max-width: 700px) {
  .about-container {
    width: 88%;
  }

  .about-container p {
    font-size: 16.5px;
  }
}

@media (max-width: 660px) {
  .about-container {
    width: 95%;
    margin-top: 45px;
  }
    
  .about-container p {
    font-size: 13.5px;
  }
}

@media (max-width: 520px) {
  .about-container li {
    font-size: 12px;
  }
}

@media (max-width: 465px) {
  .about-container li {
    font-size: 10.5px;
  }
}