.contact-form {
  width: 80%;
  margin: auto;
  margin-bottom: 60px;
}

.contact-form .form {
  color: rgb(186, 199, 223);
  width: 88%;
  margin: auto;
  background-color: rgb(18, 21, 45);
  padding: 20px;
  border: solid rgba(56, 63, 110, 0.307) 1.5px;
  border-radius: 10px;
}

.contact-form h1, .contact-form p {
  text-align: center;
}

.contact-form p {
  font-size: 17.5px;
  font-weight: 600;
  color: rgba(186, 199, 223, 0.795);
  line-height: 18px;;
  width: 40%;
  margin: auto;
  padding-bottom: 10px;
}

@media (max-width: 1005px) {
  .contact-form p {
    width: 75%;
  }
}

@media (max-width: 562px) {
  .contact-form p {
    font-size: 16.5px;
    width: 95%;
  }
}

@media (max-width: 434px) {
  .contact-form p {
    font-size: 15.5px;
    width: 98%;
  }
}

.contact-form input,
.contact-form textarea {
  margin-bottom: 7px; 
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-icon {
  width: 40px;
  height: 40px;
  animation: spin 1.5s linear infinite; 
  color: #61DBFB;
  margin-left: 25px;
  position: relative;
  top: -45%;
}

.loading-text {
  font-size: 18px; 
  color: rgba(186, 199, 223, 0.795);
  margin-left: 15px;
  position: relative;
  top: -45%;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.message {
  font-size: 28px;
  color: rgb(186, 199, 223);
  margin-top: 50px;
  padding: 10px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}
