.hero-container {
  display: flex;
  gap: 5px;
  position: relative;
  overflow: hidden;
  flex-direction: row-reverse;
  top: 50px;
  margin-top: 75px;
}

.hero-container .headshot img {
  height: auto; 
  display: block;
  margin: auto;
  margin-right: 30px;
  padding: 0;
  position: relative;
  width: 75%;
  opacity: 0; 
  animation: fadeIn 2.5s ease-in forwards; 
  animation-delay: 6s;
}

.headshot {
  position: relative; 
}

.main-content {
  padding: 20px;
}

.toggle-icon-container {
  position: absolute;
  left: 3px; 
  top: 1.5%; 
  transform: translateY(-50%);
  cursor: pointer;
}

.toggle-icon {
  font-size: 24px; 
  color: rgb(186, 199, 223);
}

.toggle-icon:hover {
  color: #61DBFB;
}

.hero-container p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: normal;
  background-color: rgb(18, 21, 45);
  padding: 20px;
  border: solid rgba(56, 63, 110, 0.307) 1.5px;
  border-radius: 10px;
  font-size: 16.5px;
  font-weight: 500;
  color: rgba(186, 199, 223, 0.795);
  width: 420px;
  opacity: 0; 
  animation: fadeIn 0.8s ease-in forwards;
  animation-delay: 3s;
  backdrop-filter: blur(120px);
}

.hero-details {
  display: block;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin: auto;
  max-width: 100%;
  position: relative;
  bottom: 60px;
  left: 200px;
}

.hello-world {
  font-family: "Quicksand", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  color: rgba(186, 199, 223, 0.791);
  font-size: 1.8rem;
  letter-spacing: -1px;
  position: relative;
  top: 16px;
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards; 
  animation-delay: 0.3s;
}

.hero-details h1 {
  font-size: 5.3em;
  margin-bottom: 3px;
  display: inline-block;
  font-weight: 600;
  width: 650px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.becoming-gradient {
  color: rgba(186, 199, 223, 0.82); 
  font-weight: 300;
  opacity: 0; 
  animation: fadeIn 0.3s ease-in forwards; 
  animation-delay: 2.0s; 
}

.gradient {
  background: linear-gradient(to right, rgb(141, 169, 220), rgb(186, 199, 223)); 
  -webkit-background-clip: text;
  color: transparent;
  opacity: 0;
  animation: fadeIn 0.3s ease-in forwards; 
  animation-delay: 2.0s; 
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.button-container {
  position: relative;
  right: 35px;
  bottom: 15px;
}

.contact-button, .about-button {
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 12.5px;
  background-color: rgba(0, 6, 39, 0.256);
  color: rgb(186, 199, 223);
  margin-right: 10px;
  padding: 10px 20px;
  padding-top: 12px;
  padding-bottom: 12px;
  max-width: 120px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-position 3s linear; 
  background-size: 200% auto;
  background-position: 100% 0;
  display: inline-block;
  opacity: 0; 
  transform: translateX(100%); 
  animation: slideInFromRight 1.2s ease-in forwards;
  animation-delay: 5s; 
  width: 120px;
  backdrop-filter: blur(50px);
  border: solid rgba(56, 63, 110, 0.307) 1px;
}

@keyframes slideInFromRight {
  from {
    opacity: 0;
    transform: translateX(100%); 
  }
  to {
    opacity: 1;
    transform: translateX(0); 
  }
}

.contact-button {
  backdrop-filter: blur(50px);
  background-color: rgba(18, 21, 45, 0.882);
}

.contact-button:hover {
  background-position: 0 0;
  color: #61dcfbd1;
  transition: 0.3s ease-out;
  font-weight: 700;
}

.about-button:hover {
  color: rgb(186, 199, 223);
  transition: 0.3s ease-out;
  box-shadow: 0 0 7px #61dcfbd1;
  font-weight: 700;
}

.contact-button .icons, .about-button .icons {
  vertical-align: middle;
}

@media (max-width: 1475px) {
  .hero-container .headshot img {
    left: 18px;
  }
}

@media (max-width: 1280px) {
  .hero-container {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .hero-container .headshot img {
   max-width: 35%;
   display: flex;
   position: relative;
   margin: auto;
   left: 36px;
  }

  .hero-details {
    display: flex;
    padding-bottom: 25px;
    margin: auto;
    position: relative;
    left: 75px;
    bottom: 75px;
  }

  .hero-details h1 {
    font-size: 5em;
  }
}

@media (max-width: 885px) {
  .hero-details {
    display: flex;
    position: relative;
    text-align: center;
    transition: all 0.3s ease;
    left: 30px;
    bottom: 40px;
  }

  .hero-details h1 {
    font-size: 4.0em;
  }

  .hero-container .headshot img {
    display: flex;
    position: relative;
    left: 28px;
    width: 85%;
   }

  .hero-container p {
    width: 350px; 
    font-size: 0.9rem;
    text-align: left;
    margin: auto;
  }

   .about-button, .contact-button {
    font-size: 12px;
    padding: 10px;
    margin-top: 18px;
    align-items: center;
    position: relative;
    left: 200px
  }

  .button-container {
    position: relative;
    right: 0;
    bottom: 0;
  }
} 

@media (max-width: 845px) {
  .hero-details h1 {
    font-size: 3.8em;
  }
}

@media (max-width: 700px) {
  .hero-details {
    transition: ease;
  }

  .hero-details h1 {
    font-size: 3.8em;
  }
}

  .hero-details h1 {
    font-size: 2.9em;
    max-width: 420px;
    margin: auto;
  }

  .hero-details {
    display: flex;
    position: relative;
    bottom: 20px;
    transition: ease;
  }

  .hero-details p {
    position: relative;
    top: 4px;
    padding: 12px;
    font-size: 13px;
  }

  .about-button, .contact-button {
    font-size: 11.5px;
    padding: 10px;
    margin-top: 20px;
    align-items: center;
    position: relative;
    left: 35px;
    width: 120px;
  }

@media (max-width: 555px) {
  .hello-world {
     font-size: 0.8rem;
     font-weight: 600;
     position: relative;
     top: 10px;
  }

  .hero-details {
    width: 100vh;
    margin: auto;
    position: relative;
    margin-top: 20px;
  }

  .hero-details h1 {
    font-size: 2.0em;
    max-width: 250px;
    margin: auto;
    padding-bottom: 5px;
  }

  .hero-container {
    margin: auto;
    margin-top: 50px;
    position: relative;
  }

  .hero-container .headshot img {
    display: flex;
    position: relative;
   }

  .hero-container p {
    width: 250px;
    font-size: 0.75rem;
    margin: auto;
    position: relative;
    top: 6px;
  }

  .button-container {
    padding-top: 12px;
    margin: auto;
  }

  .about-button, .contact-button {
    font-size: 10px;
    padding: 10px;
    position: relative;
    left: 5px;
  }
}

@media (max-width: 475px) {
  .hero-container p {
    padding: 10px;
  }
}

@media (max-width: 430px) {
  .hero-container {
    display: block;
  }

  .hero-container .headshot img {
    display: flex;
    position: relative;
   }

   .hero-details p {
    top: 12px;
   }

   .hello-world {
    font-size: 14px;
    position: relative;
    top: 8px;
   }

   .hero-details h1 {
    font-size: 32px;
   }

   .hero-details h1, .hero-details p {
    text-align: left;
    word-wrap: break-word;
   }

   .about-button, .contact-button {
    font-size: 9.5px;
    padding: 10px;
    position: relative;
   }
   
}

@media (max-width: 428px) {
  .hero-details p {
    font-size: 10px;
  }
}

@media (max-width: 364px) {
  .toggle-icon {
    opacity: 0;
    transition: 0.3s ease-out;
  }
}

      